body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
}

*::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

ul li {
  list-style-type: none;
  margin-right: 20px;
}

ul li a {
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: black;
}

.row {
  display: flex;
  align-items: center;
}

.row-photo {
  display: flex;
  align-items: center;
}

.row-end {
  display: flex;
}

.row.justi {
  justify-content: space-between;
}

.container-L {
  position: relative;
  max-width: 1380px;
  margin: 0 auto;
  padding: 21px 60px;
}

.hamburgerButton {
  display: none;
}

.container-M {
  max-width: 1380px;
  height: 700px;
  margin: 0 auto;
}

.buttonIn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 48px;
  background: #F7785D;
  border-radius: 60px;
  padding: 20px;
  font-family: 'Visuelt Pro';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
}

.buttonBorder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 60px;
  padding: 20px;
  font-family: 'Visuelt Pro';
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #2E2A2B;
  text-align: center;
  border: 1px solid #F7785D;
}

.Logo {
  width: 130px;
  height: 40px;
}

.Logo img {
  width: 100%;
  height: 100%;
}

.inputContainer {
  width: 89px;
  height: 32px;
  margin-right: 10px;
}


.inputContainer input,
input::placeholder {
  color: #2E2A2B;
  border: none;
  outline: none;
}

.inputContainer img {
  margin-left: -110px;
  width: 20px;
  height: 20px;
}

.row.justi .en {
  width: 49px;
  height: 32px;
  justify-content: space-around;
}

.row.justi .en p {
  font-size: inherit;
  font-weight: inherit;
  color: #2E2A2B;
}

.row.justi .en img {
  width: 6px;
  height: 4px;
}

.innerContext {
  position: relative;
  width: 1380px;
  height: 700px;
  background: linear-gradient(0deg, rgba(46, 53, 64, 0.1), rgba(46, 53, 64, 0.1)), url(./asset/Rectangle\ 571.png);
}

.innerContext .topLeft {
  position: absolute;
  left: 90px;
  max-width: 760px;
}

.innerContext .topLeft h1 {
  font-weight: 400;
  font-size: 72px;
  padding-top: 120px;
  line-height: 100%;
  color: #FFFFFF;
}

.innerContext .topLeft h2 {
  font-weight: 700;
  font-size: 72px;
  padding-top: 5px;
  line-height: 100%;
  color: #FFFFFF;
}

.innerContext .topLeft .innerTextBox {
  width: 760px;
  margin-top: 64px;
  background-color: #FFFFFF;
}

.innerTextBox .innertext {
  padding: 15px 25px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  background: #F7785D;
}

.innerTextBox .innerInput {
  padding: 40px;
  justify-content: space-between;
}

.innerTextBox .innerInput .search {
  display: flex;
  align-items: center;
}

.innerTextBox .innerInput .search input {
  padding: 12px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid #747B86;
}

.innerTextBox .innerInput .search input input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #747B86;
}

.type {
  width: 245px;
  height: 48px;
  justify-content: space-between;
}

.type span {
  font-weight: 400;
  font-size: 18px;
  color: #2E2A2B;
}

.type p {
  font-weight: 400;
  font-size: 12px;
  color: #747B86;
}

.section-picks {
  padding: 80px 120px;
  position: relative;
  overflow-x: scroll;
}

.picks-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.picks-title-1 {
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  color: #2E2A2B;
}

.picks-node {
  width: 15px;
  height: 15px;
  background: #085856;
}

.picks-title-2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #2E2A2B;
  padding-right: 10px;
}

.picks-content {
  display: flex;
  align-items: center;
  position: relative;
}

.picks-card {
  position: relative;
  width: 450px;
  height: 600px;
  background-color: #320e0e;
  margin-right: 20px;
}

.picks-inner {
  position: absolute;
  top: 40px;
  left: 40px;
}

.picks-inner p {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  padding-bottom: 10px;
}

.picks-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picks-textinner.active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  gap: 20px;
  color: #fff;
  padding: 40px;
  background: #2E2A2B;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}

.picks-textinner {
  display: none;
}

.picks-textinner a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  gap: 10px;
  width: 140px;
  height: 48px;
  border: 1px solid #F7785D;
  border-radius: 60px;
}

.prev-button,
.next-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
  cursor: pointer;
}

.picks-1 {
  left: -50px;
  transform: rotate(180deg);
}

.picks-2 {
  right: -30px;
  transform: rotate(360deg);
}

.new-1 {
  left: 101px;
  top: 110px;
  transform: rotate(180deg);
}

.new-2 {
  right: 101px;
  top: 110px;
  transform: rotate(360deg);
}

.new-home {
  position: relative;
  width: 100%;
  height: 300px;
  background: linear-gradient(90deg, rgba(46, 42, 43, 0.8) 0%, rgba(46, 42, 43, 0) 100%);
}

.new-home div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  left: 300px;
  top: 100px;
}

.new-home div h1 {
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  padding-bottom: 20px;
}

.new-home div p {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}

.new-home img {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.section-why {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F3F4F2;
  padding: 100px 120px;
}

.why-paragrafy {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 700px;
  padding-top: 30px;
  padding-bottom: 83px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #2E2A2B;
}

.why-title {
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  color: #2E2A2B;
}

.why-icon {
  position: relative;
  width: 910px;
}

.why-line-1 {
  position: absolute;
  right: 55%;
  width: 271px;
  top: 21%;
  height: 1px;
  border: 1px solid #40B2A4;
}

.why-line-2 {
  position: absolute;
  left: 53.8%;
  width: 280px;
  top: 21%;
  height: 1px;
  border: 1px solid #40B2A4;
}

.why-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.why-image div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.why-image div p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 78px;
  color: #2E2A2B;
}

.why-support {
  position: relative;
  width: 1200px;
}

.why-imageConteiner {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.why-absolute {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: -1.79%;
  padding: 20px;
  right: -1.73%;
  top: 0;
  bottom: -1.79%;
}

.why-vector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.why-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why-content h1 {
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  color: #FFFFFF;
  margin-bottom: 50px;
  margin-top: 20px;
}

.why-content-p1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.why-content-p2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 18px;
}

.articles-paragrafy {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #2E2A2B;
  padding-bottom: 30px;
}

.articles-card {
  position: relative;
  width: 315px;
  height: 418px;
  background-color: #320e0e;
  margin-right: 20px;
}

.articles-card img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.articles-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #F3F4F2;
  padding: 20px 20px 31px 20px;
  gap: 10px;
}

.articles-textinner a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  gap: 10px;
  width: 140px;
  height: 48px;
  border: 1px solid #F7785D;
  border-radius: 60px;
}

.articles-date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.articles-date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.articles-content-p {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2E2A2B;
}

.articles-date-p1 {
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
  color: #085856;
}

.articles-date-p2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
  text-align: right;
  color: #747B86;
}

.section-touch {
  padding: 80px 120px;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.section-Conteiner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #2E2A2B;
  padding: 80px 120px;
}

.section-Conteiner h1 {
  color: #FFFFFF;
}

.touch-ınputConteiner {
  display: flex;
  gap: 20px;
}

.touch-ınput {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}

.touch-ınput-card {
  display: flex;
  flex-direction: column;
}


.touch-ınput div .card-ınput {
  width: 400px;
  height: 48px;
  border: none;
  outline: none;
  background: #2E2A2B;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.touch-ınput div .card-ınput::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
}

.touch-ınput div label {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #747B86;
}

.touch-ınput div textarea {
  width: 410px;
  height: 120px;
  border: none;
  outline: none;
  background: #2E2A2B;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.touch-ınput div textarea::placeholder {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  opacity: 0.5;
}

.touch-button {
  width: 410px;
  display: flex;
  justify-content: space-between;
}

.touch-button-radio {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buttonCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: #F3F4F2;
}

input[type="radio"] {
  border: none;
  outline: none;
  display: none;
}

.Fetured-title {
  display: flex;
  align-items: center;

}

.Fetured-title .row {
  margin-right: 151px;
}

.Fetured-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 108px;
}

.letus {
  display: flex;
  flex-direction: column;

  gap: 60px;
  justify-content: flex-start;
}

.letus p {
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  color: #2E2A2B;
}

.letus-flex {
  display: flex;
  align-items: center;
  padding-top: 26px;
}

.letus .card {
  width: 387px;
}

.letus .card-ınput {
  width: 387px;
  height: 48px;
  border: none;
  outline: none;
  border-bottom: 1px solid #747B86;
}

.letus-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: #101010;
}

.letus-flex-radio {
  gap: 13.70px;
  margin-right: auto;
}

.section-footer {
  padding: 0px 20px;
}

.section-footer-component {
  display: flex;
  flex-direction: column;
}

.footer-layout {
  padding: 80px 120px;
  background-color: #F4E3DF;
}

.footer-layout-flex {
  display: flex;
  align-items: center;
  gap: 138px;
}

.footer-links-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.footer-link-flex {
  display: flex;
  flex-direction: column;

}

.footer-link-flex p {
  padding: 38px 0px;
  font-size: 24px;
  line-height: 120%;
  color: #2E2A2B;

}

.footer-link-flex a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #2E2A2B;

}

.sosyal-footer {
  display: flex;
  flex-direction: column;
  padding-bottom: 58px;
  padding-top: 30px;
}

.sosyal-footer a {
  font-size: 20px;
  line-height: 160%;
  color: #2E2A2B;
}

.footer-2 {
  padding: 80px 120px;
  background-color: #2E2A2B;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-2-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-2-flex p {
  font-size: 24px;
  line-height: 120%;
  color: #FFFFFF;
  padding-bottom: 35px;
}

.footer-2-flex .link {
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  opacity: 0.5;
}

.footer-2-flex .buttonLarge {
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: #FFFFFF;
  padding-top: 38px;
}



@media only screen and (max-width: 600px) {

  .container-L {
    position: relative;
    max-width: 1380px;
    margin: 0 auto;
    padding: 13px 15px;
  }

  nav {
    display: none;
  }

  .inputContainer input,
  input::placeholder {
    color: #2E2A2B;
    border: none;
    outline: none;
    display: none;
  }

  .inputContainer img {
    width: 35px;
    height: 35px;
    margin-left: 40px;
  }

  .row.justi .en {
    width: 49px;
    height: 32px;
    justify-content: space-around;
    display: none;
  }

  .innerContext .topLeft h1 {
    font-size: 42px;
    line-height: 42px;
  }

  .innerContext .topLeft h2 {
    font-size: 42px;
    line-height: 42px;
  }

  .innerContext {
    position: relative;
    width: 375px;
    height: 360px;
    background: linear-gradient(0deg, rgba(46, 53, 64, 0.1), rgba(46, 53, 64, 0.1)), url(./asset/Rectangle\ 571.png);
  }

  .innerContext .topLeft {
    position: absolute;
    padding: 20px;
    max-width: 760px;
    left: 0;
  }

  .innerContext .topLeft .innerTextBox {
    width: 335px;
    margin-top: 148px;
  }

  .innerTextBox .innerInput {
    padding: 40px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .innerTextBox .innerInput .search input {
    background: #F3F4F2;
  }

  .innerTextBox .innerInput {
    gap: 14px;
    background-color: #F3F4F2;
  }

  .section-picks {
    padding: 60px 20px;
  }

  .picks-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .picks-title-1 {
    font-size: 28px;
  }

  .picks-card {
    width: 300px;
    height: 400px;
    margin-right: 10px;
  }

  .picks-1 {
    display: none;
  }

  .picks-2 {
    display: none;
  }

  .new-home {
    width: 374px;
    height: 232px;
  }

  .new-home div {
    left: 68px;
    top: 56px;
  }

  .new-home div h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    padding-bottom: 20px;
  }

  .new-1 {
    left: 27px;
    top: 202px;
  }

  .new-2 {
    right: 24px;
    top: 201px;
  }

  .section-why {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 80px 20px;
  }

  .why-title {
    font-size: 28px;
  }

  .why-paragrafy {
    font-size: 20px;
  }

  .why-line-1 {
    display: none;
  }

  .why-line-2 {
    display: none;
  }

  .why-image {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 57px;
  }

  .why-image div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .why-image div p {
    margin-bottom: 0px;
  }

  .why-absolute {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: -1.79%;
    right: 0;
    padding: 0px;
    top: 0;
    bottom: -1.79%;
  }

  .why-content h1 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .section-touch {
    padding: 0px;
  }

  .section-Conteiner {
    gap: 20px;
    padding: 60px 20px;
  }

  .touch-ınputConteiner {
    display: flex;
    flex-direction: column;
  }

  .touch-button {
    width: 330px;
  }

  .Fetured-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
  }

  .Fetured-title .row {
    margin-right: 0px;
  }

  .Fetured-image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    gap: 4px;
  }

  .letus {
    padding: 20px;
    background-color: #F3F4F2;
  }

  .letus p {
    font-size: 24px;
  }

  .letus .card-ınput {
    width: 387px;
    height: 48px;
    border: none;
    background-color: #F3F4F2;
    border-bottom: none;
  }

  .letus-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
  }

  .letus-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 26px;
    gap: 21px;
  }

  .articles-textinner {
    width: 90%;
  }

  .articles-textinner a {
    width: 100%;
  }

  .footer-layout {
    padding: 70px 50px;
    background-color: #F4E3DF;
  }

  .footer-layout-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 45px;
  }

  .footer-links-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
  }

  .footer-2 {
    padding: 40px 20px;
    background-color: #2E2A2B;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-2 {
    padding: 40px 20px;
    background-color: #2E2A2B;
    display: flex;
    gap: 81px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-2-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #4C4849;
  }

  .why-support {
    position: relative;
    width: 335px;
    height: 400px;
  }
}